import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const BlogPage = () => {
	return (
		<Layout>
			<Seo title="Blog" />
			<h2>Blog</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/_CAT3717.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>
			<h3>Not really blogging these days</h3>
			<p>
				I keep a bread-crumb trail of what I read and which films I watch <a href="//muse-feed.tumblr.com/">here</a>.
	 		</p>
			<p>
				If you're looking for my very old blog, from my old blogging days, I kept it.
				Just look <Link to="/blog2005/">here</Link>.
			</p>
		</Layout>
	);
};

export default BlogPage;
